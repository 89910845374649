











import { ref } from '@vue/composition-api';

export default {
  components: {
    SignUp: () => import('@/views/Signup.vue')
  },

  setup(props, ctx) {
    const openSignup = ref(true);

    localStorage.setItem('isSponsor', 'true');

    return { openSignup };
  }
};
